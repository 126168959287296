.creative {
  font-family: $type-content;
  margin-bottom: 100px;
}

.creative-header {
  margin: 0 auto 50px;
  max-width: 1200px;
  text-align: center;

  @media screen and (max-width: 500px) {
    ul {
      width: 100%;
    }
  }

  @media screen and (max-width: $medium) {
    ul {
      width: 90vw;

      li {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media screen and (min-width: 501px) and (max-width: $medium) {
    max-width: 100%;

    .header-image {
      height: 200px;
    }
  }

  @media screen and (min-width: ($medium + 1)) {
    .header-image {
      height: 300px;
    }

    ul {
      justify-content: space-between;
      width: 600px;
    }
  }

  .header-image {
    display: block;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  h1 {
    color: $grey1;
    margin: 2em auto .5em;

    .pop {
      color: $primary;
      font-family: $type-headline;
    }
  }

  ul {
    display: inline-flex;
    list-style-type: none;
    padding: 0;

    a {
      color: $grey1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding-bottom: 1em;
      width: 100px;
      height: 60px;
      transition: transform 200ms ease;

      @media screen and (max-width: 500px) {
        width: auto;
        padding: 0 .5em;
      }

      &.active {
        border-bottom: 2px solid $primary;
      }

      &:hover {
        font-weight: 700;
        transform: scale(1.1);
        transition: transform 200ms ease;
      }

      svg {
        color: $primary;
        margin-bottom: .5em;
      }
    }
  }
}

.creative-features {
  margin: 0 auto;

  @media screen and (max-width: $medium) {
    width: 100%;
    max-width: 95vw;
  }

  @media screen and (min-width: ($medium + 1)) {
    width: 1200px;
    max-width: 90vw;
  }

  .landmark {
    margin-left: 0;
  }

  .featured-project {
    display: flex;
    margin-top: 3em;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    @media screen and (min-width: 501px) and (max-width: $medium) {
      width: 90%;
    }

    @media screen and (max-width: $medium) {
      flex-direction: column;

      .image {
        margin-bottom: 2em;
      }
    }

    .image {
      height: 200px;

      @media screen and (min-width: ($medium + 1)) {
        min-width: 400px;
        width: 400px;
        margin-right: 2em;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .feature-summary {
      .title {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: 500px) {
          justify-content: space-between;
        }

        @media screen and (min-width: 501px) and (max-width: $medium) {
          justify-content: flex-start;

          .tag {
            margin-left: 2em;
          }
        }

        @media screen and (min-width: ($medium + 1)) {
          justify-content: space-between;
        }

        h2 {
          margin: 0;
        }

        .tag {
          font-size: .85em;
          font-weight: 700;

          a {
            color: $primary-dark;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.creative-body-container {
  width: 1200px;
  max-width: 90vw;
  margin: 0 auto;

  .creative-body {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: $medium) {
      flex-direction: column;
    }

    @media screen and (min-width: ($medium + 1)) {
      flex-direction: row;
    }

    p {
      color: $content;
      font-weight: 500;
      margin-bottom: 2em;
    }

    img {
      max-width: 100%;
      margin-bottom: 1em;
    }

    .tip {
      padding: 1em;
      background: rgba($primary, .1);
      width: 80%;
      margin: 1em auto 2em;

      &:before {
        display: inline-block;
        margin-right: .5em;
        content: 'Tip';
        font-weight: 700;
      }
    }
  }

  .creative-nav {
    width: 220px;
    margin-right: $space;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    @media screen and (min-width: 501px) and (max-width: $medium) {
      width: 90%;
      margin: 0 auto;
    }

    @media screen and (max-width: $medium) {
      ul {
        display: flex;
        justify-content: space-around;

        li {
          display: inline-block;
          flex: 1;
          text-align: center;
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        cursor: pointer;
        margin-bottom: .5em;
        padding: .5em;

        &:hover {
          background: rgba($primary, .10);
        }

        &.active {
          color: $primary-dark;
        }
      }
    }
  }
}

.creative-thumbs {
  display: grid;
  grid-gap: $space;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px, 1fr);
  grid-auto-flow: dense;
  width: 100%;

  .rectangle {
    grid-column-end: span 2;
    grid-row-end: span 1;
  }

  .large-square {
    grid-column-end: span 2;
    grid-row-end: span 2;
    max-height: 100% !important;
    height: 100% !important;
  }

  .item {
    background: $primary;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    max-height: 330px;
    height: 330px;

    &:hover {
      .photo-info {
        opacity: 1;
        transition: opacity 200ms ease;

        p {
          color: white;
        }

        a {
          text-decoration: none;

          p {
            color: white;
          }

          .link-text {
            color: lighten($primary, 10%);
            text-decoration: underline;
          }
        }
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .photo-info {
      background-color: rgba($grey1, .85);
      bottom: 0;
      color: lighten($primary, 20%);
      font-size: .85em;
      left: 0;
      opacity: 0;
      padding: .75em $space;
      position: absolute;
      right: 0;
      transition: opacity 200ms ease;
      width: -webkit-fill-available;

      svg {
        margin-left: .5em;
      }

      p {
        font-weight: 500;
        margin: 0 0 .25em;
      }
    }
  }
}

.craft-body {
  img {
    max-width: 100%;
  }
}

.diy-body {
  flex: 1;

  @media screen and (min-width: $medium) {
    margin-right: 2em;
  }
}

.recipe-body {
  flex: 1;

  @media screen and (min-width: $medium) {
    margin-right: $lg-space;
  }

  .recipe-cover {
    display: block;
    height: 400px;
    margin-bottom: $lg-space;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .recipe-instructions {
    h2 {
      font-weight: 500;
    }
  }
}

.prep-info {
  flex-wrap: wrap;
  display: flex;
  margin-bottom: $lg-space;

  .prep-info-detail {
    margin-right: 50px;
    margin-bottom: 1em;

    strong {
      color: $primary-dark;
    }

    p {
      margin: $sm-space 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: $sm-space 0;

      li {
        background: rgba($primary, .2);
        border-radius: 20px;
        display: inline-block;
        font-size: .85em;
        line-height: .85em;
        margin-bottom: 1em;
        margin-right: 1em;
        padding: .75em 1em;
      }
    }
  }
}

.instruction-step {
  color: $primary-dark;
  display: block;
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: .25em;

  &:before {
    color: rgba($primary-dark, .65);
    display: inline-block;
    font-family: $icon;
    font-weight: 700;
    margin-right: .5em;
    text-align: center;
    width: 25px;
  }

  &.prep-step {
    &:before {
      content: '\f5ce';
    }
  }

  &.stir-step {
    &:before {
      content: '\f5a7';
    }
  }

  &.cook-step {
    &:before {
      content: '\f769';
    }
  }

  &.decorate-step {
    &:before {
      content: '\f79d';
    }
  }

  &.wait-step {
    &:before {
      content: '\f2f2';
    }
  }

  &.serve-step {
    &:before {
      content: '\f2e6';
    }
  }
}

.materials {
  &.mobile {
    @media screen and (min-width: ($medium + 1)) {
      display: none;
      visibility: hidden;
    }
  }

  &.desktop {
    @media screen and (max-width: $medium) {
      display: none;
      visibility: hidden;
    }

    @media screen and (min-width: ($medium + 1)) {
      width: 350px;
    }
  }
}

.diy-materials {
  background: rgba($primary, .10);
  border-radius: 10px;
  border: 1px solid $primary;
  display: block;
  height: fit-content;
  margin-bottom: 1em;
  padding: $space;

  h3 {
    border-bottom: 1px solid rgba($primary, .3);
    color: $primary-dark;
    font-size: 1em;
    margin-top: .5em;
    padding-bottom: .5em;
  }

  ul {
    list-style-type: none;
    margin: 0 0 2em .5em;
    padding: 0;

    li {
      margin-bottom: 1em;

      .amount {
        font-weight: 700;
        margin-right: .5em;
      }
    }
  }
}

.recipe-card {
  background: rgba($primary, .10);
  border-radius: 10px;
  border: 1px solid $primary;
  height: fit-content;
  max-height: 70vh;
  overflow-y: scroll;
  padding: $space;

  &.mobile {
    @media screen and (min-width: ($medium + 1)) {
      display: none;
      visibility: hidden;
    }
  }

  &.desktop {
    @media screen and (max-width: $medium) {
      display: none;
      visibility: hidden;
    }

    @media screen and (min-width: ($medium + 1)) {
      width: 350px;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-dark; 
  }

  &.sticky {
    position: sticky;
    top: 70px;
  }

  h2 {
    color: $content;
    font-size: 1.25em;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    border-bottom: 1px solid rgba($primary, .3);
    color: $primary-dark;
    font-size: 1em;
    padding-bottom: .5em;
  }

  ul {
    list-style-type: none;
    margin: 0 0 2em .5em;
    padding: 0;

    li {
      margin-bottom: 1em;

      .amount {
        font-weight: 700;
        margin-right: .5em;
      }
    }
  }
}

.sticky-side {
  top: 5em;
  position: sticky;
}
