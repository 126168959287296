.hero {
  background-color: $primary;
  position: relative;
  
  .hero-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    position: absolute;
    z-index: 2;
    
    h1 {
      color: white;
      font-family: $type-headline;
      font-weight: 300;
    }
  }

  .scroll {
    bottom: 1em;
    color: white;
    font-size: 2.5em;
    left: 50%;
    position: absolute;
  }

  .pop {
    color: $grey1;
  }

  @media screen and (max-width: $small) {
    height: 40vh;

    h1 {
      font-size: 4em;
      margin: 0 1em;
      text-align: center;
    }

    .hero-content {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .scroll {
      display: none;
      visibility: hidden;
    }
  }

  @media screen and (min-width: ($small + 1)) {
    h1 {
      margin-bottom: 2.5em;
    }

    .hero-content {
      align-items: flex-end;
      justify-content: flex-end;
      max-width: $large;
      right: 3em;
    }
  }

  @media screen and (min-width: ($small + 1)) and (max-width: $medium) {
    height: 40vh;

    h1 {
      font-size: 4em;
    }
  }

  @media screen and (min-width: ($medium)) {
    height: 80vh;
    min-height: 650px;

    h1 {
      font-size: 6em;
    }
  }
}

.tri-large {
  opacity: .25;
  position: absolute;
  z-index: 1;

  @media screen and (max-width: $small) {
    width: 90%;
    top: 20%;
    left: 5%;
  }

  @media screen and (min-width: ($small + 1)) { 
    top: 3em;
    left: 3em;
    width: 75%;
  }
}

.tri-small {
  max-height: 350px;
  max-width: 250px;
  margin-bottom: 3em;
}

.value-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;

  @media screen and (max-width: $small) {
    &:first-child {
      margin-top: $space;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .value {
    flex: 1;
    max-width: 40%;
    text-align: center;

    svg {
      color: $primary;
      font-size: 1.5em;
    }

    p {
      font-size: .85em;
    }
  }
}
