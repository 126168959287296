$primary: #0ABAB5;
$primary-dark: #046059;
$grey1: #2B2B2B;
$grey2: #3F3F3F;
$grey3: #595959;
$light1: #EAEAEA;

$color1: #ffa69e;
$color2: #b8f2e6;
$color3: #aed9e0;
$color4: #faf3dd;

$content: $grey1;

$github: #040204;
$twitter: #00B6F1;
$instagram: #2C6A93;
$linkedin: #04669A;

$type-headline: 'Oleo Script Swash Caps', cursive;
$type-content: 'Josefin Sans', sans-serif;
$icon: "Font Awesome 5 Pro";

$space: 1em;
$sm-space: .5em;
$lg-space: 1.5em;

$std-size: 50px;

$content-width: 960px;

$small: 767px;
$medium: 992px;
$large: 1200px;
