.portfolio {
  h1 {
    font-family: $type-content;
    margin: 2em;

    @media screen and (max-width: $medium) {
      font-size: 1.75em;
      text-align: center;
    }
  }

  .gotcha {
    font-family: $type-content;
    color: $content;
    margin-bottom: 2em;
  }

  &.highlights {
    margin-top: 3em;
  }

  a {
    display: block;
    position: relative;
  }

  .image {
    width:100%;
    overflow:hidden;

    @media screen and (max-width: $small) {
      height: 200px;
    }

    @media screen and (min-width: ($small + 1)) {
      height:300px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .caption {
    bottom: 2em;
    left: 2em;

    &span {
      font-size: .85em;
    }
  }
}

.project-title {
  overflow: hidden;
  position: relative;

  .image {
    @media screen and (max-width: $small) {
      height: 30vh;
    }
  
    @media screen and (min-width: ($small + 1)) {
      height: 500px;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .title-content {
    color: white;
    position: absolute;
    bottom: $std-size;
    left: $std-size;
    z-index: 3;
  }
}

.summary {
  background: rgba($primary, .5);
  padding: $std-size;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: $small) {
    padding: ($std-size/2) $std-size;
  }

  @media screen and (min-width: ($small + 1)) {
    padding: $std-size;
  }

  h3 {
    font-family: $type-content;
    font-weight: 700;
    margin-top: 0;
  }

  .section {
    @media screen and (max-width: $small) {
      max-width: 100%;

      &:last-child {
        margin-top: 2em;
      }
    }

    @media screen and (min-width: ($small + 1)) {
      max-width: 50%;
    }
  }

  .about {
    max-width: 50%;
  }

  p {
    margin-bottom: 0;
  }
}

.image-collection {
  display: grid;
  gap: .5em;
  grid-template-columns: auto auto;
  grid-auto-rows: auto auto;
  grid-auto-flow: dense;
  margin: 2em 0;

  &::before, &::after {
    box-sizing: border-box;
  }

  .item {
    display: inline-block;
    width: 100%;
  }

  .col1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
  }

  .col2 {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
  }

  img {
    margin: 0;
  }

  @media screen and (max-width: $small) {
    grid-template-columns: auto;

    .col1 {
      grid-row: 1 / span 2;
    }

    .col2 {
      grid-column: 1 / span 1;
      grid-row: 3 / span 2;
    }
  }
}

.full-width {
  width: 100vw;
  margin: 0 auto;
  max-width: 1000px;

  img {
    margin-bottom: 1em;
    width: 100%;
  }
}

.article {
  font-family: $type-content;
  margin: 50px auto;

  h2 {
    margin-top: 2em;

    &.title {
      margin-top: 0;
    }
  }

  h3 {
    margin: 1.5em 0 1em;
  }

  ul {
    margin-bottom: 2em;

    li {
      line-height: 1.5em;
    }
  }

  a {
    color: darken($primary, 20%);
  }

  img {
    max-width: 100%;
    margin-bottom: 1em;
  }

  @media screen and (max-width: $small) {
    width: 90vw;
  }

  @media screen and (min-width: ($small + 1)) and (max-width: ($medium - 1)) {
    padding: $std-size;
    width: 70vw;
  }

  @media screen and (min-width: $medium) {
    width: 70vw;
    max-width: 800px;
  }
}

.tags {
  font-family: $type-content;
  font-size: 1em;
  font-weight: 500;
  list-style-type: none;
  padding: 0;

  @media screen and (max-width: $small) {
    margin-bottom: 0;
  }

  li {
    font-style: italic;
    padding-bottom: 1em;

    @media screen and (max-width: $small) {
      display: inline-block;
      padding-right: 1em;

      &:last-child {
        padding: 0;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.headline {
  .overlay {
    text-align: center;

    @media screen and (max-width: $small) {
      padding: 0 1em;

      h2 {
        font-size: 2em;
      }
    }

    @media screen and (min-width: ($small + 1)) {
      h2 {
        font-size: 3em;
      }
    }

    h3 {
      font-family: $type-content;
      font-size: 1.5em;
      font-weight: 300;
    }

    h2 {
      font-family: $type-headline;
      line-height: 1.25em;
      max-width: 600px;
      white-space: pre-wrap;
    }

    a, svg {
      color: white;
    }
  }
}

.headline-image {
  width:100%;
  overflow:hidden;

  @media screen and (max-width: $small) {
    height:30vh;
  }

  @media screen and (min-width: ($small + 1)) {
    height:80vh;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
