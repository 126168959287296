@import-normalize;
@import 'variables.scss';
@import 'utility.scss';
@import 'home.scss';
@import 'portfolio.scss';
@import 'code.scss';
@import 'bio.scss';
@import 'articles.scss';
@import 'creative.scss';
@import 'contact.scss';

html, body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  &:visited {
    color: darken($primary, 20%);
  }
}

a, p {
  font-family: $type-content;
}

p {
  line-height: $lg-space;
}

h1, h2 {
  font-weight: 300;
}

.nav-container {
  margin-left: -130px;

  @media screen and (max-width: $small) {
    position: absolute;
    right: 0;
  }
}

.nav {
  position: relative;
  overflow: hidden;
  height: 50px;
  width: 100%;
  align-items: center;
  display: flex;

  @media screen and (max-width: $small) {
    button {
      margin-right: 1em;
    }
  }
}

.menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  margin-right: 2em;
  text-align: left;

  @media screen and (max-width: 500px) {
    margin-left: -130px;

    &.open {
      margin-left: 0;
      width: 90vw;
    }
  }

  @media screen and (max-width: $small) {
    display: flex;
    justify-content: space-around;
    margin-right: 0;
    background: white;
    padding-top: .5em;
  }

  @media screen and (min-width: 501px) and (max-width: $small) {
    margin-left: 2em;

    &.open {
      width: 95vw;
    }
  }

  a {
    background: transparent;
    color: $grey1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    transition: all 300ms ease;
    border-bottom: 4px solid transparent;
    text-decoration: none;

    @media screen and (max-width: 500px) {
      font-size: .85em;
      padding: 0 .5em;

      &:last-child {
        padding-right: 1em;
      }
    }

    @media screen and (min-width: 501px) and (max-width: 830px) {
      padding: 0 1em;
    }

    @media screen and (min-width: 832px) {
      padding: 0 1.5em;
    }

    &:hover {
      background: rgba($primary, .3);
      color: darken($primary, 20%);
      transition: all 300ms ease;
    }

    &.active {
      border-bottom: 4px solid $primary;
    }
  }
}

.brand {
  font-size: 20px;
  min-width: 130px;

  @media screen and (max-width: $small) {
    margin-left: 1em;
  }

  a {
    color: $grey1;
    text-decoration: none;
  }
}

.header {
  align-items: center;
  background: white;
  display: flex;
  font-family: $type-content;
  height: 50px;
  justify-content: space-between;
  padding: 0 50px;
  position: sticky;
  top: 0;
  z-index: 5;

  @media screen and (max-width: $small) {
    padding: 0;
  }
}

.container {
  margin: 0 auto;
  max-width: $large;
}

.content {
  width: 100%;

  h1 {
    font-family: $type-content;
    margin: .5em 0;
  }

  h2 {
    font-family: $type-content;
    font-weight: 500;
  }
}

.arrow-link {
  text-decoration: none;
  font-weight: 500;
  color: $content;

  &:after {
    content: '\f178';
    display: inline-block;
    padding-left: .25em;
    color: $primary;
    font-size: 1.5em;
    font-family: $icon;
    vertical-align: middle;
  }

  &.block {
    display: block;
    margin-top: 2em;
  }

  // svg {
  //   font-size: 1.5em;
  //   vertical-align: middle;
  //   padding-left: .25em;
  //   color: $primary;
  // }
}

.footer {
  display: flex;
  flex-direction: row;
  height: 50px;
  background: white;
  align-items: center;
  font-size: 1.25em;
  color: $grey3;
  bottom: 0;
  z-index: 5;
  position: fixed;
  width: 100%;

  svg {
    margin-left: .75em;
  }

  a {
    svg {
      color: $grey3;
      transition: all 300ms ease;
    }

    &:hover {
      .github {
        color: $github;
      }

      .twitter {
        color: $twitter;
      }

      .instagram {
        color: $instagram;
      }

      .linkedin {
        color: $linkedin;
      }
    }
  }

  .follow-links {
    justify-self: flex-end;
    margin-left: auto;

    @media screen and (max-width: $small) {
      padding-right: 1em;
    }
  
    @media screen and (min-width: ($small + 1)) {
      padding-right: 50px;
    }
  }
}

.top-scroll {
  align-items: center;
  color: $content;
  cursor: pointer;
  font-family: $type-content;
  font-size: .85em;
  height: 40px;
  position: sticky;

  @media screen and (max-width: $small) {
    justify-self: flex-start;
    left: 1em;
  }

  @media screen and (min-width: ($small + 1)) {
    justify-self: center;
    left: 45vw;
  }

  &:hover {
    svg {
      color: $primary;
    }
  }
}
